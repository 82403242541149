import { render, staticRenderFns } from "./domain-dropdown.vue?vue&type=template&id=66101cf9&scoped=true"
import script from "./domain-dropdown.vue?vue&type=script&lang=ts"
export * from "./domain-dropdown.vue?vue&type=script&lang=ts"
import style0 from "./domain-dropdown.vue?vue&type=style&index=0&id=66101cf9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.22_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_gnkt6mqmu7ofl6lgbdc2xoqxvi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66101cf9",
  null
  
)

export default component.exports